cs.ns('app.ui.composite.content')
app.ui.composite.content.ctrl = cs.clazz({
    extend:   app.fw.abstract.ctrl,
    mixin:    [],
    dynamics: {
        contentReadService: null,
        serviceRoot:        null,
        contentTimer:       null,
        videoPlaying:       null
    },
    protos:   {

        create () {
            this.base(app.ui.composite.content.model, app.ui.composite.content.view)
            cs(this).property('ComponentJS:state-auto-increase', true)
        },

        setup () {
            this.base()

            this.contentReadService = this.publishEventToParent('getServiceURLForService', 'contentRService')
            this.serviceRoot        = this.publishEventToParent('getServiceURLForService', 'serviceRoot')
        },

        prepare () {
            this.base()

            this.observeParentModel('global:data:selectedChannel', (ev, channel) => {
                this.model.value('state:infoVisible', channel !== null && channel.info !== false)
            }, { boot: true })

            this.observeParentModel('global:data:currentContent', (ev, content) => {
                this.clearContentTimer()
                this.model.value('state:infoVisible', false)
                if (content && content.tag) {
                    let url = content.url
                    if (!url) {
                        url = sprintf(this.contentReadService, this.serviceRoot, content.tag)
                    }
                    this.model.value('data:contentURL', url, true)
                    this.model.value('state:qrcodeVisible', content.qrcode === true)
                    this.model.value('state:ribbonVisible', content.ribbon === true)
                    this.videoPlaying = content.type === 'video'
                } else {
                    this.model.value('data:contentURL', '')
                }
            }, { op: 'changed' })

            this.observeParentModel('global:state:paused', (ev, value) => {
                const content = this.model.value('global:data:currentContent')
                if (value) {
                    if (this.contentTimer) {
                        this.clearContentTimer()
                    } else if (content && content.type === 'video') {
                        this.model.value('command:pauseVideo', true)
                    }
                } else if (content) {
                    if (content.type === 'video' && this.videoPlaying) {
                        this.model.value('command:resumeVideo', true)
                    } else {
                        this.enableContentTimer(Math.max(0, content.duration))
                    }
                }
            }, { op: "changed" })

            this.observeOwnModel('event:contentChanged', () => {
                const content = this.model.value('global:data:currentContent')
                if (content) {
                    this.model.value('state:infoVisible', this.model.value('global:data:selectedChannel') !== null && content.info === true)
                    if (content.type !== 'video') {
                        this.enableContentTimer(Math.max(0, content.duration))
                    }
                }
            })

            this.observeOwnModel('event:videoEnded', () => {
                const content     = this.model.value('global:data:currentContent')
                this.videoPlaying = false
                if (content)
                    this.enableContentTimer(Math.max(0, content.duration))
            })

            this.observeOwnModel('event:error', () => {
                this.clearContentTimer()
                this.selectNextContent()
            })

            if (this.model.value('global:state:embedded')) {
                this.model.value('global:state:paused', true)
            }
        },

        clearContentTimer () {
            if (this.contentTimer) {
                window.clearTimeout(this.contentTimer)
                this.contentTimer = null
            }
        },

        enableContentTimer (time) {
            if (!this.model.value('global:state:paused')) {
                this.clearContentTimer()
                this.contentTimer = window.setTimeout(() => {
                    this.selectNextContent()
                    this.clearContentTimer()
                }, time)
            }
        },

        selectNextContent () {
            const nextContent = this.model.value('global:data:nextContent')
            if (nextContent)
                this.publishEventToParent('selectContent', nextContent.hash)
            else
                console.error('No next content available - autoplay stopped')
        }

    }
})